import { Bitmovin } from '../players/bitmovin';
import { PlaybackEvents } from './events.model';
import { PlaybackConfig, PlayerDetails, RawPlayer } from './playback.model';

export type ClassType = Bitmovin;

/**
 * Type representing a video player: expand as per requirements.
 */
export type PlayerConstructor<T> = T extends Bitmovin ? new (playerDetails: PlayerDetails) => T : unknown;

/**
 * Interface defining operations implemented for every video player type.
 *
 * Every player will have a set of expected behavior such as the ability to play a video.
 * Additional player specific behavior can be extended from this base player.
 */
export interface BasePlayer {
  readonly playVideo: (playbackConfig: PlaybackConfig, options?: PlayerOptions) => Promise<Bitmovin | void>;
  readonly initializePlayer: (containerId: string, options?: PlayerOptions) => void;
  readonly isInitialized: boolean;
  readonly playerStop: () => Promise<void>;
  readonly listenOn: (event: unknown, func: (event: unknown) => void) => void;
  readonly off: (event: unknown, func: (event: unknown) => void) => void;
  readonly getRawPlayer: () => RawPlayer;
  readonly getCurrentTime: () => number;
  readonly showError: (errorMessage: string) => void;
  readonly mute: () => void;
  readonly unmute: () => void;
}

/**
 * Mapping of implemented players.
 */
export const playerMapping: Record<string, PlayerConstructor<ClassType>> = {
  bitmovin: Bitmovin,
};

/**
 * Interface that defines video player features/capabilities e.g. autoplay.
 */
export interface PlayerOptions {
  autoplay?: boolean;
  muted?: boolean;
  seekInterval?: number;
  forwardButtonClassName?: string;
  rewindButtonClassName?: string;
  retrieveSessionToken?: () => string;
}

/**
 * Interface that defines information about a request to play a video.
 */
export interface CreatePlayOptions {
  container: string;
  entryId: string;
  token?: string;
  options?: PlayerOptions;
  events?: { [K in PlaybackEvents]?: (event: unknown) => unknown };
}
